.GridItem {
    float: left;
    padding: 8px;
    border: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;  
}

.GridWrapper {
    display: block;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    position: relative;
    border-radius: 3px;
    outline: none;  
    z-index: 0;
    overflow: auto;
}